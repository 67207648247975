<template>
	<v-app-bar
			app
			dense
			flat
			class="elevation-2"
			height="40"
			color="cell"
			:clipped-left="!$route.path.match('/dashboard')"
	>
		<v-btn icon small v-if="$route.path.match('/dashboard')" @click="$vuetify.breakpoint.width < 600 || !drawer ? drawer = !drawer  : drawer_mini = !drawer_mini">
			<v-icon small>mdi-menu</v-icon>
		</v-btn>

		<v-toolbar-title>
			<router-link style="height: 40px; width: 90px" class="router-link" :to="$store.getters.marketplace_url !== null ? $store.getters.marketplace_url : $store.getters.dashboard_url !== null ? $store.getters.dashboard_url : ''">
				<v-img
						v-if="$store.getters['auth/selected_site'] !== null && $store.getters['auth/selected_site'].logo !== null"
						style="height: 40px; width: 90px"
						contain
						:src="$store.getters['config/backend_url'] + $store.getters['auth/selected_site'].logo"
				/>
				<v-img
						v-else
						style="height: 40px; width: 90px"
						contain
						:src="$vuetify.theme.dark ? require('@/assets/logo_dark.svg') : require('@/assets/logo_light.svg')"
				/>
			</router-link>
		</v-toolbar-title>

		<v-spacer></v-spacer>

		<div v-if="contact_id && $vuetify.breakpoint.width > 600" class="paragraph">{{ $store.getters['auth/selected_site'].display_name | firstCapital }}</div>

		<v-divider v-if="contact_id && $vuetify.breakpoint.width > 600" vertical class="ml-5 mr-2" />

		<Languages />

		<Cart v-if="app_ready && $store.getters.marketplace_url !== null" />

		<Notifications :show="contact_id" />

		<Profile :contact="contact_id" :marketplace="marketplace" :pevents="pevents" />
	</v-app-bar>
</template>

<script>
	export default {
		name: "NavBar",
		props: ['pevents'],
		components: {
			Cart: () => import('@/components/navbar/Cart.vue'),
			Notifications: () => import('@/components/navbar/Notifications.vue'),
			Profile: () => import('@/components/navbar/ProfileMenu.vue'),
			Languages: () => import ('@/components/navbar/LanguageChanger.vue')
		},
		computed: {
			contact_id() {
				return this.$store.getters['auth/contact_info']
			},
			drawer: {
				get(){
					return this.$store.getters['dashboard/drawer']
				},
				set(val){
					this.$store.commit('dashboard/CHANGE_DRAWER_VAL', val)
				}
			},
			drawer_mini: {
				get(){
					return this.$store.getters['dashboard/drawer_mini']
				},
				set(val){
					this.$store.commit('dashboard/CHANGE_DRAWER_MINI_VAL', val)
				}
			},
			marketplace(){
				return this.$store.getters['marketplace_url']
			},
			app_ready(){
				return this.$store.getters['application_ready']
			}
		}
	}
</script>

<style scoped>

</style>
